<script setup lang="ts">
import type {
  BlockImage,
  PartialSome,
  Translated,
  validationInfo,
} from '@/api/types';
import MaterialIcon from '@/components/MaterialIcon.vue';
import { computed, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import InputLabel, { type IInputLabelProps } from './InputLabel.vue';
import { uploadBlob, type IBlobUrlInput, getBlobUrl } from '@/api/blob';
import TextInput from '@/components/forms/InputText.vue';
import { useNestedMVDefault } from '@/utilities/useInternalState';

const { t } = useI18n();

const props = defineProps<
  IInputLabelProps & {
    modelValue: PartialSome<Translated<BlockImage>, 'alt'> | undefined;
    blobLocation: IBlobUrlInput;
    showValidationMessage?: boolean;
    /** Used for connecting label and input */
    forName: string;
    hideDescriptionInput?: boolean;
    border?: boolean;
  }
>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: typeof props.modelValue): void;
  (e: 'reportValidationError', name: string, hasError: boolean): void;
}>();

const uploadInput = ref<HTMLInputElement>();
const urlRef = ref<HTMLInputElement>();
const imageError = ref<boolean>(false);
const loading = ref<boolean>(false);
const confirmDelete = ref<boolean>(false);

const uploadFileNotUrl = ref<boolean>(true);

const value = useNestedMVDefault(
  props,
  (val) => emit('update:modelValue', val),
  { name: '', alt: '' },
);

const previewUrl = computed(() => {
  if (!value.value?.name) return undefined;
  if (uploadFileNotUrl.value == false) {
    return value.value.name;
  } else {
    return getBlobUrl(props.blobLocation, value.value.name);
  }
});

const handleImageUpload = async (e: Event): Promise<void> => {
  const files = (e.target as HTMLInputElement).files;
  if (files && files[0]) {
    loading.value = true;
    imageError.value = false;

    try {
      const filename = await uploadBlob(props.blobLocation, files[0]);
      imageError.value = false;
      value.value.name = filename;
      value.value.alt = value.value?.alt ?? '';
    } catch {
      imageError.value = true;
    }
  }
};

const validationMessage = computed(() =>
  validationErrors.value
    .map((i) => t(`validation.${i.key}`, i.args))
    .join('\n'),
);

function satisfiesRequiredRule(): validationInfo | null {
  const condition = !!(!props.required || value.value?.name);
  return condition ? null : { key: 'required', args: {} };
}

const validationErrors = computed((): validationInfo[] => {
  return [satisfiesRequiredRule()].filter(
    (i) => i !== null,
  ) as validationInfo[];
});

function performValidation() {
  emit(
    'reportValidationError',
    props.forName,
    validationErrors.value.length > 0,
  );
}

watchEffect(() => {
  performValidation();
});

function onDelete() {
  confirmDelete.value = false;
  if (!value.value) return;
  value.value.name = '';
}
</script>

<template>
  <div class="flex flex-col whitespace-nowrap">
    <label :for="forName">
      <InputLabel v-bind="props" />
    </label>

    <div :class="[border ? 'border px-2' : '']">
      <div class="tabs-bordered w-fit my-2 font-medium">
        <button
          class="tab tab-rounded-lg tab-border-2"
          :class="uploadFileNotUrl ? 'tab-active' : ''"
          @click="uploadFileNotUrl = true; value.name = ''">
          {{t('block.schema.uploadImage')}}
        </button>
        <button
          class="tab tab-rounded-lg tab-border-2"
          :class="uploadFileNotUrl ? '' : 'tab-active'"
          @click="uploadFileNotUrl = false; value.name = ''">
          {{t('block.schema.uploadUrl')}}
        </button>
      </div>

      <div class="flex">
        <div
          v-if="uploadFileNotUrl">
          <button
            class="gnist-button mb-3 mt-2"
            :disabled="loading"
            @click.prevent="uploadInput?.click()"
          >
            {{
              value?.name
                ? t('block.schema.changeImage')
                : t('block.schema.chooseImage')
            }}
          </button>
          <span
            v-if="showValidationMessage && validationMessage"
            :data-tip="validationMessage"
            class="tooltip tooltip-right tooltip-open tooltip-warning"
          >
          </span>
        </div>
        <div
          v-if="!uploadFileNotUrl">

          <input
            :id="forName"
            ref="urlRef"
            v-model="value.name"
            type="url"
            class="w-full border p-2 mb-3 mt-2"
            :placeholder="t('block.schema.linkUrlPlaceholder')"
            :required="required"
            data-cy-id="InputLinkUrl"
            @focus="if (showValidationMessage && validationMessage) urlRef?.reportValidity();"
          />
        </div>
      </div>

      <input
        :id="forName"
        ref="uploadInput"
        type="file"
        accept="image/*"
        class="hidden"
        :data-cy-id="`InputImage_${forName ?? label?.split('.').slice(-1)}`"
        @change="handleImageUpload"
      />
      <p v-if="imageError" class="my-2 text-gnist-orange">
        {{ t('block.schema.imageError') }}
      </p>

      <div
        v-if="previewUrl"
        class="relative flex min-h-16 max-w-80 items-center justify-center bg-white"
      >
        <img
          :src="previewUrl"
          :data-cy-id="`InputImagePreview_${
            forName ?? label?.split('.').slice(-1)
          }`"
          @load="loading = false"
        />
        <div class="absolute right-2 top-1">
          <button v-if="confirmDelete" @click.prevent="onDelete">
            <MaterialIcon
              class="w-7 cursor-pointer rounded-full border bg-white text-xl hover:scale-110 hover:text-green-800"
            >
              check
            </MaterialIcon>
          </button>
          <button v-if="confirmDelete" @click.prevent="confirmDelete = false">
            <MaterialIcon
              class="ml-1 w-7 cursor-pointer rounded-full border bg-white text-xl hover:scale-110 hover:text-red-700"
            >
              close
            </MaterialIcon>
          </button>
          <button v-if="!confirmDelete" @click.prevent="confirmDelete = true">
            <MaterialIcon
              class="w-7 cursor-pointer rounded-full border bg-white text-xl hover:scale-110 hover:text-red-700"
            >
              delete
            </MaterialIcon>
          </button>
        </div>
      </div>
      <progress v-if="loading" class="progress w-80" />
    </div>

    <div v-if="!hideDescriptionInput && value && previewUrl">
      <TextInput
        v-model="value.alt"
        :for-name="`InputImageAlt_${forName ?? label?.split('.').slice(-1)}`"
        :label="t('block.schema.imageDescription')"
        class="mb-2 mt-6 w-80 border p-2"
        :placeholder="t('block.schema.imageDescription')"
        :required="required"
        :minlength="minlength"
        :maxlength="maxlength"
      />
    </div>
  </div>
</template>
