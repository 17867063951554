declare global {
  interface Window {
    env: {
      [key: string]: string;
    };
  }
}

export const frontendUrl =
  window.env?.VITE_FRONTEND_URL || import.meta.env.VITE_FRONTEND_URL || null;
/** Using `/api` as the type to simplify reading logic. It might be wrong in DEV, but not in other environments. */
export const apiUrl =
  (window.env?.VITE_API_URL as '/api') ||
  (import.meta.env.VITE_API_URL as '/api') ||
  '/api';
export const appVersion =
  window.env?.VITE_APP_VERSION || import.meta.env.VITE_APP_VERSION || null;

export const env_type: 'dev' | 'test' | 'prod' =
  import.meta.env.MODE === 'development'
    ? 'dev'
    : window.location.hostname.includes('-test')
      ? 'test'
      : 'prod';

const supported_locales = ['no', 'en'] as const;
export const SUPPORTED_LOCALES = supported_locales as Readonly<
  supported_locale[]
>;
export type supported_locale = (typeof supported_locales)[number];
export const B2C_LOCALE: { [key in supported_locale]: string } = {
  no: 'nb',
  en: 'en',
};
export const DEFAULT_LOCALE = 'no' satisfies supported_locale;

export const notFoundRouteName = 'NotFound';
export const socialPageRouteId = 'page';
export const editBlocksRouteId = 'admin';
export const editBlockVersionsRouteId = 'block_versions';
export const apimproductsRouteId = 'apimproducts';
export const mySubscriptionsRouteId = 'subscriptions';

// Should equal https://tailwindcss.com/docs/customizing-spacing#default-spacing-scale
export const twSpacingScale = [
  '0 (0px)',
  'px (1px)',
  '0.5 (0.125rem)',
  '1 (0.25rem)',
  '1.5 (0.375rem)',
  '2 (0.5rem)',
  '2.5 (0.625rem)',
  '3 (0.75rem)',
  '3.5 (0.875rem)',
  '4 (1rem)',
  '5 (1.25rem)',
  '6 (1.5rem)',
  '7 (1.75rem)',
  '8 (2rem)',
  '9 (2.25rem)',
  '10 (2.5rem)',
  '11 (2.75rem)',
  '12 (3rem)',
  '14 (3.5rem)',
  '16 (4rem)',
  '20 (5rem)',
  '24 (6rem)',
  '28 (7rem)',
  '32 (8rem)',
  '36 (9rem)',
  '40 (10rem)',
  '44 (11rem)',
  '48 (12rem)',
  '52 (13rem)',
  '56 (14rem)',
  '60 (15rem)',
  '64 (16rem)',
  '72 (18rem)',
  '80 (20rem)',
  '96 (24rem)',
] as const;
export const twSpacingNamedScale = [
  'xs (20rem)',
  'sm (24rem)',
  'md (28rem)',
  'lg (32rem)',
  'xl (36rem)',
  '2xl (42rem)',
  '3xl (48rem)',
  '4xl (56rem)',
  '5xl (64rem)',
  '6xl (72rem)',
  '7xl (80rem)',
  'screen-sm (40rem)',
  'screen-md (48rem)',
  'screen-lg (64rem)',
  'screen-xl (80rem)',
  'screen-2xl (96rem)',
] as const;
/** Can only be used for max-width */
export const twSpacingMaxWidthOnlyScale = ['prose'] as const;
